/* eslint-disable new-cap */
import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import jsyaml from 'js-yaml'
import * as monaco from 'monaco-editor'

import * as worker from 'monaco-editor/esm/vs/editor/editor.worker'
import * as jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker'
import * as cssWorker from 'monaco-editor/esm/vs/language/css/css.worker'
import * as htmlWorker from 'monaco-editor/esm/vs/language/html/html.worker'
import * as tsWorker from 'monaco-editor/esm/vs/language/typescript/ts.worker'


// 在初始化之前，先设置MonacoEnvironment
self.MonacoEnvironment = {
    getWorker(_, label) {
        if (label === 'json') {
            return new jsonWorker()
        }
        if (label === 'css' || label === 'scss' || label === 'less') {
            return new cssWorker()
        }
        if (label === 'html' || label === 'handlebars' || label === 'razor') {
            return new htmlWorker()
        }
        if (label === 'typescript' || label === 'javascript') {
            return new tsWorker()
        }
        return new worker()
    }
}

/**
 * ExampleComponent is an example component.
 * It takes a property, `label`, and
 * displays it.
 * It renders an input with the property `value`
 * which is editable by the user.
 */

const Code = (props) => {

    const { language, value, setValue, isEdit } = props

    const languageList = ['javascript', 'java', 'python', 'json', 'yaml', 'xml', 'html']

    const codeEditor = useRef(null)
    const [toggle, setToggle] = useState(false)
    const [currentLanguage, setCurrentLanguage] = useState(language)
    const [changelanguage, setChangelanguage] = useState()

    const stringData = () => {
        if (currentLanguage === 'json') {
            return JSON.stringify(value, null, 2)
        } else if (currentLanguage === 'yaml') {
            return jsyaml.dump(value)
        }
        return value
    }

    const initUi = () => {
        // 自定义主题背景色
        monaco.editor.defineTheme('CodeSampleTheme', {
            base: 'vs',
            inherit: true,
            rules: [{ background: '#fdf6e3' }],
            colors: {
                // 相关颜色属性配置
                // 背景色
                // 'editor.background': '#fdf6e3'
            }
        })
        // 初始化编辑器
        const editor = monaco.editor.create(codeEditor.current, {
            value: stringData(),
            theme: 'CodeSampleTheme',
            language: props.language,
            overviewRulerBorder: false,
            automaticLayout: true,
            readOnly: !isEdit,
            minimap: {
                enabled: false
            },
            lineNumbers: 'on',
            cursorStyle: 'UnderlineThin',
            autoIndent: true,
            glyphMargin: true,
            fontSize: 18,
            scrollbar: {
                verticalScrollbarSize: 1,
                horizontalScrollbarSize: 4
            },
            acceptSuggestionOnCommitCharacter: true,
            acceptSuggestionOnEnter: 'on',
            accessibilityPageSize: 10,
            accessibilitySupport: 'on',
            autoClosingBrackets: 'always',
            autoClosingDelete: 'always',
            autoClosingOvertype: 'always',
            autoClosingQuotes: 'always',
            codeLens: false,
            codeLensFontFamily: '',
            codeLensFontSize: 14,
            colorDecorators: false,
            comments: {
                ignoreEmptyLines: true,
                insertSpace: true
            },
            contextmenu: true,
            columnSelection: false,
            autoSurround: 'never',
            copyWithSyntaxHighlighting: true,
            cursorBlinking: 'Solid',
            cursorSmoothCaretAnimation: true,
            cursorSurroundingLines: 0,
            cursorSurroundingLinesStyle: 'all',
            cursorWidth: 2,
            folding: true,
            links: true,
            renderLineHighlight: 'gutter',
            roundedSelection: false,
            scrollBeyondLastLine: false,
        })
        // 监听内容变化
        editor.onDidChangeModelContent(() => {
            try {
                if (currentLanguage === 'json') {
                    setValue(JSON.parse(editor.getValue()))
                } else if (currentLanguage === 'yaml') {
                    setValue(jsyaml.load(editor.getValue()))
                } else {
                    setValue(editor.getValue())
                }
            } catch (error) {
                setValue(editor.getValue())
            }
        })
        // 语言改变时
        setChangelanguage((language) => {
            setCurrentLanguage(language)
            monaco.editor.setModelLanguage(editor.getModel(), language)
            setToggle(false)
        })

        // return () => editor.dispose()
    }
    useEffect(initUi, [])

    return (
        <div className='h-card h-code relative overflow-hidden h-[340px]'>
            <div className='h-full border rounded' ref={codeEditor}></div>
            <div className={`absolute top-0 right-0 z-10 bg-white rounded-md  shadow border-gray-200 transition-all duration-300 ${toggle ? 'w-32 p-2 border' : 'w-0'}`}>
                {languageList.map(item => (
                    <div key={item} onClick={() => changelanguage(item)}
                        className={`font-semibold p-1 px-2 rounded cursor-pointer hover:bg-gray-200 ${currentLanguage === item ? 'bg-indigo-500 hover:bg-indigo-500 text-white' : ''}`}>{item}</div>
                ))}
            </div>
            {/* <div onClick={() => setToggle(!toggle)} className='absolute right-0 bottom-0 w-5 h-5 cursor-pointer z-10 bookmarklet'></div> */}
        </div>
    )
}

Code.defaultProps = {
    language: 'json'
}

Code.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    language: PropTypes.string,

    /**
     * The ID used to identify this component in Dash callbacks.
     */
    isEdit: PropTypes.bool,

    /**
     * The value displayed in the input.
     */
    value: PropTypes.any,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setValue: PropTypes.func
}

export default Code;

import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

/**
 * ExampleComponent is an example component.
 * It takes a property, `label`, and
 * displays it.
 * It renders an input with the property `value`
 * which is editable by the user.
 */
const Dropdown = (props) => {
    const { value, placeholder, options, setValue, setSelect } = props;

    const [selectStatus, setSelectStatus] = useState(false)

    const [filterOptions, setFlterOptions] = useState(options)

    const changeSelect = (e) => {
        setValue(e)
        const current_options = options.filter(option => option?.method_name?.includes(e))
        setSelectStatus(true)
        setFlterOptions(current_options)
    }

    const dropdownRef = useRef(null)
    const selectRef = useRef(null)
    // document.addEventListener('click', function (event) {
    //     console.log('触发点击', event)
    //     if (dropdownRef.current?.contains(event.target)) {
    //         setSelectStatus(!selectStatus)
    //     } else if (!selectRef.current?.contains(event.target)) {
    //         setSelectStatus(false)
    //     }
    // })

    return (
        <div className='h-card h-dropdown flex-1 flex gap'>
            <div ref={dropdownRef} onClick={() => setSelectStatus(!selectStatus)} className='relative w-full flex gap-x-2 cursor-pointer justify-between items-center border rounded'>
                <input type='text' placeholder={placeholder} value={value} onChange={e => changeSelect(e.target.value)} className='form-control form-control-sm flex-1'></input>
                <div ref={selectRef} className={`absolute left-0 z-10 top-[40px] max-h-52 bg-white overflow-auto font-semibold w-full rounded-md border shadow border-gray-200 ${(!selectStatus || filterOptions.length === 0) ? 'hidden' : ''}`}>
                    {filterOptions.map((item) => (
                        <div className='text-gray-700 hover:bg-gray-200 cursor-pointer'>
                            <div className='font-semibold p-2' onClick={() => { setSelect(item) }}>{item?.method_name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

Dropdown.defaultProps = {};

Dropdown.propTypes = {
    /**
     * The value displayed in the input.
     */
    value: PropTypes.string,


    /**
     * The value displayed in the input.
     */
    placeholder: PropTypes.string,

    /**
     * The value displayed in the input.
     */
    options: PropTypes.array,

    /**
     * The value displayed in the input.
     */
    setValue: PropTypes.func,

    /**
     * The value displayed in the input.
     */
    setSelect: PropTypes.func
};

export default Dropdown;

import React, { useState, useMemo } from 'react'
import { BarsOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'

/**
 * ExampleComponent is an example component.
 * It takes a property, `label`, and
 * displays it.
 * It renders an input with the property `value`
 * which is editable by the user.
 */
const ObjectParams = (props) => {
    const { isEdit, value, setValue } = props

    const changeKey = (key1, key) => {
        const currentValue = cloneDeep(value)
        delete currentValue[key1]
        currentValue[key] = value[key1]
        setValue(currentValue)
    }

    const changeValue = (key1, key) => {
        const currentValue = cloneDeep(value)
        currentValue[key1] = key
        setValue(currentValue)
    }

    // 改变数据类型
    const changeType = (key1, key) => {
        const currentValue = cloneDeep(value)
        if (key === 'number') {
            if (isNaN(Number(value[key1]))) {
                currentValue[key1] = 0
            } else {
                currentValue[key1] = Number(key)
            }
        } else if (key === 'boolean') {
            currentValue[key1] = Boolean(key)
        } else {
            currentValue[key1] = String(key)
        }
        setValue(currentValue)
    }

    // 当前数据
    const [currentKey1, setCurrentKey1] = useState('')
    const [currentKey2, setCurrentKey2] = useState('')
    const addData = () => {
        const currentValue = cloneDeep(value)
        currentValue[currentKey1] = currentKey2
        setValue(currentValue)
        setCurrentKey1('')
        setCurrentKey2('')
    }

    // 改变数据类型
    const changeCurrentType = (key) => {
        console.log('key', key);
        if (key === 'number') {
            if (isNaN(Number(currentKey2))) {
                setCurrentKey2(0)
            } else {
                setCurrentKey2(Number(currentKey2))
            }
        } else if (key === 'boolean') {
            setCurrentKey2(Boolean(currentKey2))
        } else {
            setCurrentKey2(String(currentKey2))
        }
    }

    // 使用 useMemo 来 memoize newOptions 的计算  
    const sortedKeys = useMemo(() => {
        const sortedKeys = Object.keys(value).sort()
        return sortedKeys
    }, [value])

    return (
        // 参数
        <div className='h-params flex flex-col'>
            <div className='flex border-y'>
                <div className='w-10 border-x px-2 p-1'></div>
                <div className='flex-1 border-r px-2 py-1'>Key</div>
                <div className='flex-1 border-r px-2 py-1'>Value</div>
                <div className='flex-1 border-r px-2 py-1'>Type</div>
            </div>
            {sortedKeys.map(key1 => (
                <div className='flex border-b'>
                    <div className='w-10 border-x px-2 p-1 flex justify-center items-center cursor-pointer  text-gray-400'>
                        <BarsOutlined />
                    </div>
                    <div className='flex-1 border-r px-2 py-1'>
                        <input disabled={!isEdit} type='text' placeholder='添加键' className='form-control form-control-sm flex-1' value={key1}
                            onChange={(e) => changeKey(key1, e.target.value)}>
                        </input>
                    </div>
                    <div className='flex-1 border-r px-2 py-1 flex items-center'>
                        <input disabled={!isEdit} type='text' placeholder='添加值' className='form-control form-control-sm flex-1' value={value[key1]}
                            onChange={(e) => changeValue(key1, e.target.value)}>
                        </input>
                    </div>
                    <div className='flex-1 border-r px-2 py-1 flex justify-between items-center'>
                        <select disabled={!isEdit} className="form-select form-select-sm" value={typeof value[key1]}
                            onChange={(e) => changeType(key1, e.target.value)}>
                            <option disabled value="">请选择类型</option>
                            <option>string</option>
                            <option>number</option>
                            <option>boolean</option>
                        </select>
                        {isEdit && <div className='cursor-pointer ml-2 text-gray-500' onClick={() => {
                            const currentValue = cloneDeep(value)
                            delete currentValue[key1]
                            setValue(currentValue)
                        }}> <DeleteOutlined /></div>}
                    </div>
                </div>
            ))}
            <div className='flex border-b'>
                <div className='w-10 border-x px-2 p-1 flex justify-center items-center cursor-pointer  text-gray-400'>
                    <BarsOutlined />
                </div>
                <div className='flex-1 border-r px-2 py-1'>
                    <input type='text' placeholder='添加键' className='form-control form-control-sm flex-1' value={currentKey1} onChange={e => setCurrentKey1(e.target.value)}></input>
                </div>
                <div className='flex-1 border-r px-2 py-1 flex items-center'>
                    <input type='text' placeholder='添加值' className='form-control form-control-sm flex-1' value={currentKey2} onChange={e => setCurrentKey2(e.target.value)}></input>
                </div>
                <div className='flex-1 border-r px-2 py-1 flex justify-between items-center'>
                    <select className="form-select form-select-sm" value={typeof currentKey2}
                        onChange={(e) => changeCurrentType(e.target.value)}>
                        <option disabled={!isEdit} value="">请选择类型</option>
                        <option>string</option>
                        <option>number</option>
                        <option>boolean</option>
                    </select>
                    {isEdit && <div className='cursor-pointer text-gray-500' onClick={addData}> <PlusCircleOutlined /></div>}
                </div>
            </div>
        </div>
    )
}

ObjectParams.defaultProps = {};

ObjectParams.propTypes = {
    /**
     * bool
     */
    isEdit: PropTypes.bool,

    /**
     * array
     */
    value: PropTypes.array,

    /**
     * array
     */
    setValue: PropTypes.func
};

export default ObjectParams;

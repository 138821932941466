/* eslint-disable import/prefer-default-export */
import Model from './components/Model'
import Code from './components/Code'

import config from '../../package.json'
// eslint-disable-next-line import/no-dynamic-require
/* eslint-disable import/no-dynamic-require */
// import './tailwindcss.js';
// import 'font-awesome/css/font-awesome.min.css'
import '../output.css'
// import './theme.css'

console.log('组件版本号=>', config.version)

export { Model, Code }
